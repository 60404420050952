@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;700&display=swap);
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
body *{font-family:Poppins,sans-serif;box-sizing:border-box}
body{line-height:1;overflow-x:hidden;width:100vw}:root{--colorMain:#945C39}
h1{font-family:Poppins,sans-serif;font-weight:200;font-size:46px;line-height:56px;color:#636466;text-transform:uppercase;letter-spacing:26px}
h2,h3,h4{font-family:Poppins,sans-serif;font-weight:700;font-size:24px;line-height:32px;color:var(--colorMain)}
p{color:#777567}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}
a{text-decoration:none;color:inherit}
iframe{margin-bottom:20px}
.error{font-size:16px;color:red;margin:8px 2px;text-align:left}

@media(max-width:1200px){	
	.row{padding: 0 40px; flex-direction: column;}
	h1{font-size: 18px; letter-spacing: 10px;}
}
.rec.rec-slider-container{margin: 0;}
.rec.rec-carousel-wrapper{position: relative;}
.rec-carousel-item .slide_item,
.rec-carousel-item-visible,
.rec.rec-slider{outline: none;cursor: pointer;}

/* arrow */
.rec.rec-carousel button, 
.rec.rec-carousel button:hover,
.rec.rec-carousel button:focus{box-shadow: none;background-color: transparent;color:var(--colorMain);}


/* dots */
.rec.rec-pagination{margin: 0 92px 0 0;position: absolute;bottom: 30px;} 
.rec.rec-pagination button, 
.rec.rec-pagination button:hover,
.rec.rec-pagination button:focus{border: none;border-radius: 0;box-shadow: none;opacity: .5;background-color: #777567;height: 15px;width: 15px;}
/* dots item active */
.rec.rec-pagination button.rec-dot_active{opacity: 1;background-color: var(--colorMain);} 

/* end style carousel */

.container{width: 100%;max-width: 1277px;margin: 0 auto; position: relative;}
.row{display: flex;align-items: center;}

.contentTextVerfical h3{color:var(--colorMain); position: relative; text-transform: uppercase; letter-spacing: 5px;}
.contentTextVerfical p{font-weight: 700; line-height: 32px;letter-spacing: 2px;}

@media(min-width:1200px){
	.rec.rec-carousel{max-height: 450px;}
	.contentTextVerfical{display: flex;flex-direction: column; min-width: 330px;max-width: 370px;}
	.contentTextVerfical h3{padding-bottom: 74px;}
	.contentTextVerfical h3:after{content: ''; position: absolute; bottom: 40px; left: 0; background-color: var(--colorMain); width: 100%; height: 2px;}
	.img_After_Background{position: relative;}
	.img_After_Background:after{ z-index: -1;position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 100vw; height: 300px;content: url('../images/banner_stil_1_after.jpg');}
}

@media(max-width:1200px){
	.contentTextVerfical h3{padding-left: 45px; padding-bottom: 30px;}
	.contentTextVerfical h3{font-size: 18px;}
}
@media(min-width:1700px){
	.img_Large_Banner{width: calc(100% + 18vw);}
}

/* NAVBAR */
header{z-index: 999; position: fixed; width: 100%; background-color: white; box-shadow:0 3px 9px #00000017 ; height: 80px; display: flex;align-items: center;}
.navbar{display: flex;align-items: center;}
.navbar .navbar_logo{height: 63px; cursor: pointer;}

@media(max-width:1200px){
	.navbar.desktop{display: none;}
	.navbar .header_navbar{display: flex;width: 100%;justify-content: space-between;align-items: center;position: fixed; padding: 0 22px;}
	.navbar .button_navbar{background-color: var(--colorMain); color:white; padding: 10px;font-size: 15px;}
	
	.navbar .links{transform: translateX(100%);transition: all .3s;position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;background: white;display: flex;align-items: center;justify-content: center;flex-direction: column;}
	.navbar .links.active{transform: translateX(0%);transition: all .3s;}
	
	.navbar .links_header{width: 100%;justify-content: space-between;display: flex;position: fixed;top: 10px;padding: 0 22px;}
	.navbar .links_navbar li{cursor: pointer; margin-bottom: 30px; font-size: 18px;}
}
@media(min-width:1200px){
	.navbar.mobile{display: none;}
	.navbar .links_navbar{margin-left: 76px; display: flex;align-items: center;}
	.navbar .links_navbar li{cursor: pointer; margin-right: 50px; border-bottom: 2px solid transparent; position: relative;}
	.navbar .links_navbar li:after{content: ''; position: absolute; left: 0; bottom: -3px; width: 0%; height: 3px; background-color: var(--colorMain); transition: all .8s;}
	.navbar .links_navbar li:hover:after{ width: 100%; background-color: var(--colorMain); transition: all .5s;}
	.navbar .links_navbar li:last-child:after{ content: none;}
	.navbar .links_navbar li.button_navbar{ display: flex; align-items: center; justify-content: center; margin-left: 70px; margin-right: 0;background-color: var(--colorMain); color:white; width: 213px; height: 40px; text-align: center;}
}
/* FIM NAVBAR */

/* @media(max-width:1200px){
	section{overflow-x: hidden;}
} */

/* //1 */
.section_1 {padding-top: 120px; overflow: hidden;}
.section_1 .banner_2 img{padding-left: 70px;}
.section_1 .img_After_Background:before{background: #945c39; z-index: -1;position: absolute;overflow: hidden;left: -100vw;top: 0;width: 100vw;height: 300px;content: url('../images/banner_stil_1_before.png');}

@media(max-width:1200px){
	.section_1 .row{padding: 0;}
	.section_1 .banner_1 img{width: 100%;}
}

/* //2 */
.section_2 h2{text-align: center; padding: 50px 0; text-transform: uppercase; letter-spacing: 2px;}
.section_2 h3{font-weight: 200; padding-top: 30px;}
.section_2 h5{font-weight: 500; color:#636466; padding-top: 10px; text-transform: uppercase;}
.section_2 .row{align-items: flex-start; justify-content: space-between; position: relative;}
.section_2 p{line-height: 24px;padding-top: 15px;}
.section_2 .rec.rec-carousel {max-height: initial;}
.section_2 .rec.rec-carousel button, 
.section_2 .rec.rec-carousel button:hover, 
.section_2 .rec.rec-carousel button:focus {color:var(--colorMain);}

@media(max-width:1200px){
	.section_2 {padding-bottom: 50px;}
	.section_2 {overflow: hidden;}
	.section_2 .video{min-width: 100vw;}
	.section_2 *{text-align: center;}
	.section_2 .row{padding: 0;}
	.section_2 .slide_item{max-width: 100%;}
	.section_2 .author:before{content: ''; width: 100%; height: 2px; position: absolute;top: 115px;background-color:var(--colorMain);z-index: -1;}
	.section_2 .column-1 .author{display: flex; align-items: center; flex-direction: column; justify-content: center;}
	.section_2 h2{margin: 50px 0 25px 0;font-size: 15px; padding: 0 40px;}
	.section_2 .rec.rec-pagination{margin: 0;bottom: 0;left: 15px;bottom: 0px;}
	.section_2 .author:before{content: ''; width: 130%; right: -100%; height: 2px; position: absolute;top: 115px;background-color:var(--colorMain);z-index: -1;}
	.section_2 .rec-arrow-right, .section_2 .rec-arrow-left{top: 20%;position: absolute;}
	.section_2 .rec-arrow-right{right: 0;}
	/* .section_2 .rec-arrow-left{left: 20px;} */
}


@media(min-width:768px) and (max-width:1200px){
	.section_2 p{margin-bottom: 50px;}
}

@media(min-width:1200px){
	.section_2 {padding-bottom: 94px;}
	.section_2 p{max-width:390px;}
	.section_2 .column-1{padding-right: 100px;}
	.section_2 .author:before{content: ''; width: 100%; height: 2px; position: absolute;top: 115px;background-color:var(--colorMain);z-index: -1;}
	.section_2 .rec.rec-pagination {margin: 0 90px 0 0;position: absolute;bottom: 25px;}
}


/* //3 */
.section_3 .container{position: relative; margin-bottom: 30px;}
.section_3 .container *{color:white;}
.section_3 .contentTextVerfical li{min-width: 380px; margin-bottom: 10px;}
.section_3 .contentTextVerfical h3{position: relative;}
.section_3 .contentTextVerfical h3:after{content: ''; background-color: white; left: -60px; width: 125%;}
@media(max-width:1200px){
	.section_3 {overflow: hidden;}
	.section_3 .row{padding: 0;}
	.section_3 .column-1 img{width: 100%;}
	.section_3 .column-2{width: 100%; padding: 20px 40px;}
	.section_3 {background-color: var(--colorMain);}
	.section_3 .contentTextVerfical h3{padding: 0; padding-bottom: 20px; margin-bottom: 20px;}
	.section_3 .contentTextVerfical li{font-size: 13px;margin-bottom: 10px;}
	.section_3 .contentTextVerfical h3:after{content: '';background-color: white;left: -40px;width: 100%;bottom: 0;height: 2ps;min-width: 20px;height: 2px;position: absolute;}
}
@media(min-width:1200px){
	.section_3 .container:after{content: ''; z-index: -1; position: absolute; left: 0; top: -50px; width: 100vw; height: 100%; background-color:var(--colorMain);}
	.section_3 .img_After_Background:after{top: 50px; left: 50px;}
	.section_3 .column-2{padding-left: 60px; }
}

/* //4 */
.section_4 .container:before{content: url('../images/before_section_5.jpg');position: absolute;left: -49px;top: 145px;height: 1045px;overflow: hidden;}
@media(max-width:1200px){
	.section_4 .row{padding: 0;}
	.section_4 .column-1{margin-top: 50px;}
	.section_4 .column-2 {width: 100%;}
	.section_4 .column-2 img{width: 100%;}
}
@media(min-width:1200px){
	.section_4 {overflow: hidden;position: relative;top: 23px;}
}

/* //5 */
.section_5 {margin: 73px 0 50px 0;}
.section_5 .contentTextVerfical{text-align: right;}
.section_5 .column-1 img{position:relative;}
@media(max-width:1200px){
	.section_5{margin-top: 50px;}
	.section_5 {overflow: hidden;}
	.section_5 .row{padding: 0; flex-direction: column-reverse;}
	.section_5 .column-1{width: 100%;}
	.section_5 .column-1 img{width: 100%;}
	.section_5 .column-2.contentTextVerfical h3{padding-right: 45px; padding-left: 0;}
}
@media(min-width:1200px){
	.section_5 .row{justify-content: space-between;}.section_5 .column-1 img{max-width:132%; right:22%;}.section_5 .column-1{width: 800px;}.section_5 .column-2{width: 477px;}
}
@media(min-width:1700px){
	.section_5 .column-1 img{max-width:152%;right:42%;}
}

/* //6 */
.section_6 {overflow: hidden;}
@media(max-width:1200px){
	.section_6{margin-bottom: 50px;}
	.section_6 .row{padding: 0;}
	.section_6 .column-2{width: 100%;}
	.section_6 .column-2 img{width: 100%;}
}

@media(min-width:1200px){
	.section_6 .contentTextVerfical {padding-bottom: 120px;}
	.section_6 .img_Large_Banner{padding-left: 50px;}
	.section_6 .contentTextVerfical h3{letter-spacing: 8px;}
	.section_6 .contentTextVerfical h3:after{width: calc(100% + 50px) ;}
}

/* //8 */
.section_8 {background-color: #777567;}
.section_8 .slide{display: flex; justify-content: center;}
.section_8 .slide .slide_item{margin-right: 35px;}
.section_8 .slide .slide_item:last-child{margin-right:0}

.section_8 .rec.rec-carousel button, .rec.rec-carousel button:hover, .rec.rec-carousel button:focus{color:white;}
.section_8 .rec.rec-pagination button.rec-dot_active{background-color: white;}
.section_8 .rec.rec-pagination{position: relative;top: 20px;}
.section_8 .rec.rec-pagination button, .rec.rec-pagination button:hover, .rec.rec-pagination button:focus{background-color: white;}

@media(max-width:1200px){
	.d-none{display: none !important;}
	.section_8{padding:50px 0;}
	.section_8 .rec.rec-pagination{display: none;}
}

@media(min-width:1200px){
	.section_8{padding: 119px 0 65px 0; margin-top: -69px;};
}

.zoomGallery{position: fixed;top: 0;left: 0;width: 100vw;height: 100vh;background: #000000fa;z-index: 1000; display: flex; align-items: center; justify-content: center;}
.zoomGallery .close{z-index: 1001; cursor: pointer; font-size: 40px;padding: 5px 10px;position: absolute;right: 0;top: 0;color: white;}
.zoomGallery img{max-width: 100%;}
.zoomGallery .slide_item{margin-right: 0 !important;}
.zoomGallery .rec.rec-carousel{max-height: initial;}
.viewGallery{position: relative; display: flex; width: 90vw;height: 90vh;background-color: transparent;}


/* //9 */

.section_9{padding-top: 50px}
.section_9 .column-2 h1{padding-bottom: 52px;}
.section_9 .column-2 .row{align-items: flex-start;}
.section_9 .line_description{padding-bottom: 24px;}
.section_9 .line_description h3{color:var(--colorMain); font-weight: bold; font-size: 16px; text-transform: uppercase;}
.section_9 .line_description p{font-weight: 500;line-height: 24px;}

@media(max-width:1200px){
	.section_9 .row{padding:0;}
	.section_9 .column-2 h1{text-align: center; font-size: 26px;}
	.section_9 .column-2 .row.lines_descriptions{padding:0 40px; margin-bottom: 50px;}
	.section_9 .column-1 {width: 100%;}
	.section_9 .column-1 img {width: 100%;}
	.section_9 .row.content{flex-direction: column-reverse;}
	.section_9 .line_description{text-align: center}
}

@media(min-width:1200px){
	.section_9 .column-2 h1{padding-bottom: 52px; letter-spacing: 26px;}
	.section_9 .row{align-items: flex-start;}
	.section_9 .column-2{padding-left: 65px}
	
	.section_9 .content.row::after, .section_9 .content.row::before{content: ''; width: 30px; height: 300px; background-color:var(--colorMain);position: absolute;top: 50%;transform: translateY(-50%);}
	.section_9 .content.row::before{left: -30px;}
	.section_9 .content.row::after{right: -30px;}
	.section_9 .line_description{min-width: 330px;}
	.section_9 .line_description p{max-width: 345px;}
}

.section_9_1{
	display: flex;
    align-items: center;
    justify-content: center;
	margin: 50px auto 0 auto;
	padding: 0 20px;
}
.section_9_1 video{ width: 100%;max-width: 100%;}
@media(min-width:1200px){
	.section_9_1 video{ width: 100%; max-width: 900px;}
}

/* //10 */
.section_10 {margin: 127px 0 0 0; position: relative;}
.section_10 .row{align-items: flex-start;}
.section_10 .img_After_Background{position: initial;}
.section_10 .img_After_Background::after{z-index: -2; top: 60px;}
.section_10 .column-1 * {color: white;}
.section_10 *{outline: none;}
.section_10 .column-1 h1{padding-bottom: 49px;}
.section_10 .column-1 .slide_item .item__header{display: flex; justify-content: space-between;}
.section_10 .column-1 .slide_item .item__header img{width: 130px;height: 89px;}
.section_10 .column-1 .slide_item h2{font-weight: 700; letter-spacing: 5px;}
.section_10 .column-1 .slide_item p{font-weight: 200; padding: 5px 0 15px 0; font-size: 24px;}
.section_10 .column-1 .slide_item h3{font-weight: 500; padding-bottom: 5px; font-size: 16px;}
.section_10 .column-1 .slide_item h4{font-weight: 200; letter-spacing: 4px; padding-bottom: 5px;}
.section_10 .rec.rec-carousel{max-height: initial;}

.section_10 .rec.rec-carousel button, 
.section_10 .rec.rec-carousel button:hover,
.section_10 .rec.rec-carousel button:focus{color:white;}

.section_10 .rec.rec-pagination{display: none;}
.section_10 .rec-arrow-left,
.section_10 .rec-arrow-right{position: absolute;z-index:2;}

@media(max-width:1200px){
	.section_10{margin: 50px 0 0 0;background-color: var(--colorMain); overflow: hidden;}
	.section_10 .rec-arrow-left, .section_10 .rec-arrow-right{top: 50%;transform: translateY(-50%);}
	.section_10 .row {width: 100%;}
	.section_10 .column-1 {width: 100%;}
	.section_10 .column-1 h1{text-align: center; padding: 15px 0 25px 0;}
	.section_10 .slide_item {display: flex; flex-direction: column; align-items: center;}
	.section_10 .item__header{width: 100%; max-width: 400px;}
	.section_10 .slide_item .planta{width: 100%; max-width: 400px; margin: 15px auto;}
	.section_10 .rec-arrow-left,
	.section_10 .rec-arrow-right{bottom: 35px;}
	/* .section_10 .rec-arrow-left{left: 20px;} */
	.section_10 .rec-arrow-right{right: 0px;}
	.section_10 .column-1 .slide_item h3{text-align: center;}
	.section_10 .column-1 .slide_item h4{font-size: 15px; text-align: center;}
}
@media(min-width:1200px){
	.section_10 .column-1{position: relative; right: 35px; min-width: 350px;}
	.section_10::before{z-index: 34; content: '';height: 520px;background-color: var(--colorMain);position: absolute;bottom: 190px;left: -100vw;width: 170vw;z-index: -1;}
	.section_10 .rec-arrow-left,.section_10 .rec-arrow-right{padding-bottom: 200px;}
	.section_10 .rec-arrow-right{left: 430px;position: absolute;}
}

@media(min-width:1700px){
	.section_10 .column-1::before{width: 150vw;}
}

.section_11 {padding: 50px 0;}
.section_11 h1{text-align: center;}
.section_11 .column-2 li{margin-bottom: 5px; display: flex; min-width: 3300px;}
.section_11 .column-2 li span{min-width: 16px; color:var(--colorMain); font-weight: bold; font-size: 16px;}
.section_11 .column-2 li p{margin-left: 15px;color:#777567; font-weight: bold; font-size: 16px;}

/* //11 */
@media(max-width:1200px){
	.section_11 {overflow: hidden;}
	.section_11 .row{flex-direction: column;}
	.section_11 .column-2 li p{font-weight: 600; font-size: 12px;}
	.section_11 .column-2 {padding-left: 50px; width: 100%;}
	.section_11 .column-1 p{text-align: center;font-weight: 600;font-size: 12px;text-transform: uppercase;letter-spacing: 2px;line-height: 19px;}
}
@media(min-width:1200px){
	.section_11 h1{padding-bottom: 50px;}
	.section_11 .column-1 {padding: 0 40px 0 0;}
}


/* //12 */
.section_12 .container *{ color: white;}
.section_12 .container::before{content: ''; width: 200vw;height: calc(100% - 140px); background-color:var(--colorMain);position: absolute;top: 0;left: -100vw;z-index: -1;}
.section_12 h1{padding-bottom: 10px;}
.section_12 .description .column-2 p{letter-spacing: 2px;text-transform: uppercase;line-height: 22px;}
.section_12 .description{padding: 50px 0;}

@media(max-width:1200px){
	.section_12 .description{display: flex; justify-content: center; flex-direction: column; text-align: center;}
	.section_12 .container::before{min-height: 100%;padding-bottom: 30px;}
	.section_12 .column-1 p{display: none;}
	.section_12 .description .column-2 p{text-align: center;font-size: 12px;letter-spacing: 2px;padding: 0 40px;}
	.section_12 .row{padding: 0 4%;}
	.section_12 .row img{max-width: 100%; margin-top: 25px;}
}
@media(min-width:1200px){
	.section_12 .description .column-2{padding: 0  0  0 102px; max-width: 530px;}
}

/* //13 */
.section_13 h1{text-align: center;padding-bottom: 20px;}
.section_13 form{width: 100%;}

@media(max-width:1200px){
	.section_13{padding-top: 60px; padding-bottom: 50px;}
	.section_13 h1{font-size: 18px;}
	.section_13 p{font-size: 10px; font-weight: 700; padding: 0 15px 15px; text-align: center; letter-spacing: 2px;}
}
@media(min-width:1200px){
	.section_13{padding: 50px 0;}
}


/* //FOOTER */
footer{background-color:#F5F5F5; padding: 50px 0 0 0;}
footer .row{padding-bottom: 15px;}
footer .container{display: flex; align-items: center; justify-content: center; flex-direction: column;}
footer .container h1{font-size: 24px; line-height: 32px; letter-spacing: initial; padding: 15px 0;}
footer .container p{color: var(--colorMain); padding-bottom: 50px; text-transform: uppercase; letter-spacing: 3px;}

footer .copyright{padding: 50px 0; text-align: center; background-color:#777567;}
footer .copyright p{color:white; font-size: 12px; max-width: 900px; margin: 0 auto; line-height: 24px;}
footer img{max-width: 100%;}

@media(max-width:1200px){
	footer .container h1{font-size: 18px;}
	footer .container p{font-size: 10px; font-weight: 700; padding: 0 15px 15px; text-align: center; letter-spacing: 2px;}
	footer .copyright p{font-size: 8px; padding: 0 15px; line-height: 150%;}
}

.d-none{
	display: none;
}
@media(min-width:1200px){
	.d-xl-block{
		display: block;
	}
	.d-xl-none{
		display: none !important;
	}
}
