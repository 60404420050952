form {
  margin-bottom: 90px;
  max-width: 900px;
  margin: 0 auto;

  label{
    padding-bottom: 8px;
    display: block;
    color:#636466;
  }

  .form-row {
    display: grid;
    gap: 20px;

    @media (min-width: 1200px) {
      .input-nome,
      .input-email {
        grid-column: 1 / 4;
      }
      .input-telefone{
        grid-column: 1 / 2;
      }
      .input-celular {
        grid-column: 2 / 4;
      }
    }

    input,
    textarea {
      &::placeholder {
        color: #bbbbbb;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
      }
      &:focus {
        outline: none;
      }
      width: 100%;
      border: 1px solid #C7C7C7;
      padding: 10px 15px;
      border-radius: 4px;
      @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 34px;
      }
    }
  }
  .form-row--button {
    cursor: pointer;
    background-color: #945C39;
    color: white;
    font-size: 19px;
    line-height: 33px;
    border: none;
    border-radius: 0;
    margin-top: 30px;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    @media (min-width: 1200px) {
      width: 249px;
      float: right;
    }
  }
}
